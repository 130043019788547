export const MenuList = [
    {
        title: "Home",
        url:"/"
    },
    {
        title: "Social",
        url:"/social"
    },
    {
        title: "Contact",
        url:"/contact"
    },
];